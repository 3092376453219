<template>
  <CRow>
    <CCol>
      <div v-if="serverErrors.length">
        <CAlert v-model="showDismissibleAlert" color="warning" closeButton>
          <ul>
            <li v-for="err in serverErrors" :key="err">{{ err }}</li>
          </ul>
        </CAlert>
      </div>
      <form v-on:submit.prevent="onSubmitted">
        <div class="row">
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>Ward Code</label>
              <input
                  type="text"
                  class="form-control"
                  v-model="ward.ward_number"
                  placeholder="Enter Ward Code"
                  required
              />
            </div>
          </div>
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>Area name</label>
              <input
                  type="text"
                  class="form-control"
                  v-model="ward.area_name"
                  placeholder="Enter area name"
              />
            </div>
          </div>
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>PS</label>
              <input
                  type="text"
                  class="form-control"
                  v-model="ward.ps"
                  placeholder="Enter PS"
              />
            </div>
          </div>
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>Coverage</label>
              <textarea
                  class="form-control"
                  v-model="ward.coverage"
                  placeholder="Enter coverage"
              />
            </div>
          </div>
        </div>

        <button type="submit" class="btn btn-primary">Submit</button>
      </form>
    </CCol>
  </CRow>
</template>

<script>

import Textarea from "../settings/fields/Textarea.vue";

export default {
  name: "CreateWard",
  components: {Textarea},
  props: {
    rscodeLists: {
      type: Array,
      default: []
    }
  },
  data: () => {
    return {
      showToast: false,
      toastr: "",
      ward: {
        ward_number: "",
        area_name: "",
        ps: "",
        coverage: "",
      },
      serverErrors: [],
      showDismissibleAlert: false,
    };
  },
  methods: {
    onSubmitted() {
      this.$store
          .dispatch("Wards/storeWard", this.ward)
          .then(() => {
            this.showToast = true;
            this.$toastr.s('success', "Ward successfully created.", "Ward Created");
            setTimeout(() => {
              this.$emit("onSavedWard");
            }, 350);
          })
          .catch((err) => {
            this.showDismissibleAlert = true;
            this.serverErrors = [];
            if (err.response.data.message) {
              this.serverErrors.push(err.response.data.message);
            }

            let errors = err.response.data.errors;
            this.showToast = true;
            for (let field of Object.keys(errors)) {
              this.$toastr.e("error", errors[field][0], "Error!");
            }
          });
    },
  }
};
</script>

<style scoped>
</style>
